<template>
  <section id="event-count-item">
    <div class="content">
      <div class="content-wrapper">
        <div class="blue">Informações dos eventos do dia:</div>
        <div class="light">
          Total: {{ total }} | Disponível: {{ available }} | Agendado: {{ scheduled }} |
          Encaixe: {{ scheduleFitting }}
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { getCurrentClinic } from "@/utils/localStorageManager";

export default {
  name: "EventCount",
  components: {},
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    date: Date,
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      total: 0,
      scheduled: 0,
      scheduleFitting: 0,
      available: 0,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      // Check if the last event retrieve was more than 1 second ago
      const lastEventRetrieve = localStorage.getItem("lastScheduleCountEventRetrieve");

      if (lastEventRetrieve) {
        const lastEventRetrieveDate = new Date(lastEventRetrieve);
        const currentDate = new Date();
        const diff = Math.abs(currentDate - lastEventRetrieveDate) / 1000;
        if (diff < 1) {
          return;
        }
      }

      localStorage.setItem("lastScheduleCountEventRetrieve", new Date());

      const parsedDate = new Date(this.date).toISOString();
      const isLoading = this.$loading.show();
      try {
        this.api
          .getClinicEventCount(this.clinic.id, parsedDate, JSON.stringify(this.filters))
          .then((res) => {
            this.total = res.data.total;
            this.scheduled = res.data.scheduled;
            this.scheduleFitting = res.data.schedule_fitting;
            this.available = res.data.available;
          })
          .catch((err) => {
            console.log("Err", err);
          });
      } catch (err) {
        console.log(err);
      } finally {
        isLoading.hide();
      }
    },
  },
  watch: {
    date() {
      this.getData();
    },
    filters() {
      this.getData();
    },
  },
};
</script>
<style lang="scss">
#event-count-item {
  gap: 16px;
  .content {
    background: white;
    border-radius: 8px;
    padding: 16px 25px;
    border: 1px;
    .content-wrapper {
      height: fit-content;
      width: auto;
      background: #f8f8fd;
      padding: 4px 8px 4px 8px;
      border-radius: 8px;
      margin-left: -9px;
    }
    .blue {
      color: var(--Blue-500, #305bf2);
      font-family: Nunito Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 21px */
    }
    .light {
      color: #525c7a;
      font-family: Nunito Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }
  }
}
</style>
